// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
// Import Swiper React components E

// gsap S
import React, { useEffect, useRef } from 'react';
import gsap, { Power4 } from 'gsap';
// gsap E

// css S
import '../css/value-proposition.css'
import '../css/value-proposition_res.css'
// css E

// source S
import UiImg1_1 from '../images/value_proposition/ui_img1_1.png'
import UiImg1_2 from '../images/value_proposition/ui_img1_2.png'
import UiImg1_3 from '../images/value_proposition/ui_img1_3.png'
import UiImg2_1 from '../images/value_proposition/ui_img2_1.png'
import UiImg2_2 from '../images/value_proposition/ui_img2_2.png'
import UiImg3_1 from '../images/value_proposition/ui_img3_1.png'
import UiImg3_2 from '../images/value_proposition/ui_img3_2.png'
import UiImg4_1 from '../images/value_proposition/ui_img4_1.png'
import UiImg4_2 from '../images/value_proposition/ui_img4_2.png'
// source E

function ValueProposition() {
  const observer = useRef(null);

  useEffect(() => {
    const textWrapper = document.querySelectorAll('.pointSpan');
    textWrapper.forEach((element) => {
      element.innerHTML = element.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );
    });

    const animatePoints = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const tl = gsap.timeline();
          tl.staggerFrom(
            entry.target.querySelectorAll('.letter'),
            1.5,
            {
              color: '#ff99db',
              opacity: 0,
              ease: Power4.easeInOut,
            },
            0.05,
            0.2 // 딜레이
          );

          observer.current.unobserve(entry.target); // Stop observing once animation is triggered
        }
      });
    };

    observer.current = new IntersectionObserver(animatePoints, {
      root: null,
      rootMargin: '0px',
      threshold: 0.2, // Adjust this threshold as needed
    });

    textWrapper.forEach((element) => {
      observer.current.observe(element);
    });
  }, []);

  return (
    <section id="ValueProposition">
      <h2><span>가치 제안</span></h2>
      <ul className='wrap'>
        <li>
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[Autoplay, Pagination]}
          >
            <SwiperSlide>
              <img src={UiImg1_1} alt='부상경험률 UI 이미지' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={UiImg1_2} alt='부상 부위 UI 이미지' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={UiImg1_3} alt='주요 발생 부상종류 TOP5 UI 이미지' />
            </SwiperSlide>
          </Swiper>
          <div className='textDiv'>
            <h4 className='fadeInLeft'>시장동향</h4>
            <h3>
              생활체육에 대한 참여도가
              높아지면서 <span id='heroTextAnim' className='pointSpan'>체육활동 중 부상
              경험도 늘어나고 있는 것으로
              나타나 체육활동 중 부상
              위험을 미리 예측할 수 있는
              환경이 요구되는 상황</span>
            </h3>
          </div>
        </li>
        <li>
          <div className='textDiv'>
            <h4 className='fadeInLeft'>급성심정지 발생 동향</h4>
            <h3>
              급성 심정지 환자는 10~19세에서 6.7명, 80세 이상은 493.6명으로 연령이 높을수록 증가하는 경향을 보이고 있으며, 병원 밖
              급성심장정지 발생률은 2006년 39.8%에서 2021년 64.7%로
              크게 증가하여 <span className='pointSpan'>연령이 높은 분들의 외출 시 건강 모니터링이 필요함</span>
            </h3>
          </div>
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[Autoplay, Pagination]}
          >
            <SwiperSlide>
              <img src={UiImg2_1} alt='급성심장정지 발생 원인 및 발생 장소 UI 이미지' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={UiImg2_2} alt='병원 밖 급성심장정지 발생률 UI 이미지' />
            </SwiperSlide>
          </Swiper>
        </li>
        <li>
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[Autoplay, Pagination]}
          >
            <SwiperSlide>
              <img src={UiImg3_1} alt='응급안전알림 서비스 오작동 현황 UI 이미지' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={UiImg3_2} alt='응급안전알림 서비스 출동 건수 UI 이미지' />
            </SwiperSlide>
          </Swiper>
          <div className='textDiv'>
            <h4 className='fadeInLeft'>시장 대체제 필요성</h4>
            <h3>
              Health Guard는 모니터링
              대상의 신체 이상을 감지하는 기술을 통해 오작동 문제로
              인해 서비스가 무의미한
              <span className='pointSpan'> ‘응급안전알림서비스’를
              대체할 수 있을 것으로 전망함</span>
            </h3>
          </div>
        </li>
        <li>
          <div className='textDiv'>
            <h4 className='fadeInLeft'>서비스 수요 전망</h4>
            <h3>
              아이 안전사고 모니터링에 대한
              수요 확대와 웨어러블 시장의
              성장세를 통해 <span className='pointSpan'>부모들의 아이를
              위한 서비스 이용이 늘어날 것으로 전망</span>
            </h3>
          </div>
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[Autoplay, Pagination]}
          >
            <SwiperSlide>
              <img src={UiImg4_1} alt='부모가 걱정하는 아이 안전사고 UI 이미지' />
            </SwiperSlide>
            <SwiperSlide>
              <img src={UiImg4_2} alt='웨어러블 의료기기 시장 추세 UI 이미지' />
            </SwiperSlide>
          </Swiper>
        </li>
      </ul>
    </section>
  )
}

export default ValueProposition;