// css S
import './css/footer.css'
// css E

// source S
import Logo from './images/common/footer_logo.svg'
import Document1 from './document/terms_of_service.pdf'
import Document2 from './document/privacy_statement.pdf'
// source E

function Footer() {
  return (
    <footer id='footer'>
      <div className='wrap'>
        <img src={Logo} alt='푸터 로고' />
        <div>
          <address>
            업브레인<br />
            사업자등록번호 : 404 &#45; 35 &#45; 01028&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br className='mobileView' />대표 : 김 영 석<br />
            서울특별시 강남구 테헤란로 70길 12, 402호(대치동 H타워)<br />
            1899-1745 / <span>info@upbrainhg.com</span>

          </address>
          <small>Copyright &copy; UpBrain/HealthGuard All rights reserved.</small>
          <ul>
            <li>
              <a href={Document1} download='HealthGuard 서비스 이용 약관_rev1.0.pdf'>서비스 이용약관</a>
            </li>
            <li>
              <a href={Document2} download='HealthGuard 개인정보 처리방침_rev1.0.pdf'>개인정보 처리방침</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer;