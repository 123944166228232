// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
// Import Swiper React components E

import { useEffect, useState } from 'react';

// css S
import '../css/main.css'
import '../css/main_res.css'
// css E

// source S
import healthGuard from '../images/main/healthguard.svg'
import StoreWatch from '../images/main/playstore_watch.svg'
import StoreApp from '../images/main/playstore_app.svg'
import Icon1 from '../images/main/icon1.svg'
import Icon2 from '../images/main/icon2.svg'
import Icon3 from '../images/main/icon3.svg'
import Icon4 from '../images/main/icon4.svg'
import PhoneFrame from '../images/main/phone_frame.svg'
import BgH from '../images/main/bg_h.png'
import BgG from '../images/main/bg_g.png'
import OverFame from '../images/main/over_frame.svg'
import MockupImg1_1 from '../images/main/mockupimg1_1.png'
import MockupImg1_2 from '../images/main/mockupimg1_2.png'
import MockupImg2_1 from '../images/main/mockupimg2_1.png'
import MockupImg2_2 from '../images/main/mockupimg2_2.png'
import MockupImg3_1 from '../images/main/mockupimg3_1.png'
import MockupImg3_2 from '../images/main/mockupimg3_2.png'
import MockupImg3_3 from '../images/main/mockupimg3_3.png'
import MockupImg3_4 from '../images/main/mockupimg3_4.png'
import MockupImg4_1 from '../images/main/mockupimg4_1.png'
import MockupImg4_2 from '../images/main/mockupimg4_2.png'
import MockupImg4_3 from '../images/main/mockupimg4_3.png'
import AiChat1 from '../images/main/ai_chat1.svg'
import AiChat2 from '../images/main/ai_chat2.svg'
import BgCir from '../images/main/bg_cir.svg'
// source E

function Main() {

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => {
          if (prevIndex === 3) {
            return 0;
          } else {
            return prevIndex + 1;
          }
        });
      }, 2320);

     


      return () => clearInterval(interval);
    }, 400);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <section id="main">
      <h2 className="blind">main page</h2>
      <div>
        <div className='firstScreenWrap'>
          <div className='firstScreen wrap'>
            <div>
              <h2>건강 모니터링 플랫폼</h2>
              <h3>헬스가드로 나와 가족의 건강을 함께 지켜주세요.</h3>
              <ul>
                <li>
                  <a href='#'><img src={StoreApp} alt='Play Store 로고' /></a>
                </li>
                <li>
                  <a href='#'><img src={StoreWatch} alt='Play Store 로고' /></a>
                </li>
              </ul>
            </div>
            <img className='healthText' src={healthGuard} alt='Health Guard' />
          </div>
        </div>
        <div className='secScreenWrap'>
          <div className='secScreen wrap'>
            <h2 className='fadeInUp'>
              헬스가드는 웨어러블 연동을 통한<br />
              건강 데이터 기반 AI 건강 관리 서비스 플랫폼입니다.
            </h2>
            <ul>
              <li className='fadeInUp'>
                <img src={Icon1} alt='워치 일러스트' />
                <h3>신체 한계치에 대한 사전 알람</h3>
              </li>
              <li className='fadeInUp' style={{transitionDelay:'0.15s'}}>
                <img src={Icon2} alt='부상 당한 사람 일러스트' />
                <h3>부상 방지 서비스 제공</h3>
              </li>
              <li className='fadeInUp' style={{transitionDelay:'0.3s'}}>
                <img src={Icon3} alt='휴대폰 일러스트' />
                <h3>유저간의 건강 상태 모니터링</h3>
              </li>
              <li className='fadeInUp' style={{transitionDelay:'0.45s'}}>
                <img src={Icon4} alt='경고 아이콘' />
                <h3>이상 징후 발생 시 경고 알람 서비스 제공</h3>
              </li>
            </ul>

          </div>
        </div>
      </div>

      <div>
        <div className='wrap step1'>
          <div className='textWrap'>
            <h3 className='fadeInRight'>나의 건강 지킴이</h3>
            <h2>
              나의 일상과 운동 시<br />
              신체 건강 한계치를<br className='mobileView' /> 모니터링 할 수 있어<br />
              나의 건강을 지킬 수 있습니다.
            </h2>
          </div>
          <div className='imgWrap'>
            <img src={PhoneFrame} alt='핸드폰 프레임' />
            <div>
              <Swiper
                spaceBetween={0}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img src={MockupImg1_1} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={MockupImg1_2} alt='' />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <img className='bgImg bgH' src={BgH} alt='배경이미지 H' />
        </div>
        <div className='wrap step2'>
          <img className='overFrame' src={OverFame} alt='팝업 이미지' />
          <div className='imgWrap'>
            <img src={PhoneFrame} alt='핸드폰 프레임' />
            <div>
              <Swiper
                spaceBetween={0}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img src={MockupImg2_1} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={MockupImg2_2} alt='' />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className='textWrap textAni'>
            <h3 className='fadeInLeft'>신체 한계치 알람 서비스</h3>
            <h2 className={`${activeIndex === 0 ? 'on' : ''} pcView`}>
              신체 한계치 직전에 경고 알람 서비스를<br />
              제공하여 사전에 부상을 방지할 수 있습니다.
            </h2>
            <h2 className={`${activeIndex === 1 ? 'on' : ''} pcView `}>
              수집된 건강의 AI 알고리즘에 의한<br />
              학습 후 개인 신체 한계치 정의
            </h2>
            <h2 className={`${activeIndex === 2 ? 'on' : ''} pcView`}>
              건강 항목 별 의료법 기준에 의한<br />
              개인 신체 한계치 정의
            </h2>
            <h2 className={`${activeIndex === 3 ? 'on' : ''} pcView`}>
              갑작스런 큰 움직임 감지 시 사고의<br />
              위험성 여부에 대한 확인
            </h2>
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              pagination={{
                type: "fraction",
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className='mobileView mobileSwiperAni'
            >
              <SwiperSlide>
                <h2>
                  신체 한계치 직전에 경고 알람 서비스를
                  제공하여 사전에 부상을 방지할 수 있습니다.
                </h2>
              </SwiperSlide>
              <SwiperSlide>
                <h2>
                  수집된 건강의 AI 알고리즘에 의한
                  학습 후 개인 신체 한계치 정의
                </h2>
              </SwiperSlide>
              <SwiperSlide>
                <h2>
                  건강 항목 별 의료법 기준에 의한
                  개인 신체 한계치 정의
                </h2>
              </SwiperSlide>
              <SwiperSlide>
                <h2>
                  갑작스런 큰 움직임 감지 시 사고의
                  위험성 여부에 대한 확인
                </h2>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className='wrap step3'>
          <div className='textWrap'>
            <h3 className='fadeInRight'>건강 상태 모니터링</h3>
            <h2>
              Wearable 기기에서 제공된 모든<br className='pcView' />
              건강 정보를 스마트폰으로 연동하여<br className='pcView' />
              모니터링 할 수 있습니다.
            </h2>
          </div>
          <div className='imgWrap'>
            <img src={PhoneFrame} alt='핸드폰 프레임' />
            <div>
              <Swiper
                spaceBetween={0}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img src={MockupImg3_1} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={MockupImg3_2} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={MockupImg3_3} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={MockupImg3_4} alt='' />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <img className='bgImg bgG' src={BgG} alt='배경이미지 G' />
        </div>
        <div className='wrap step4'>
          <div className='imgWrap'>
            <img src={PhoneFrame} alt='핸드폰 프레임' />
            <div>
              <Swiper
                spaceBetween={0}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img src={MockupImg4_1} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={MockupImg4_2} alt='' />
                </SwiperSlide>

                <SwiperSlide>
                  <img src={MockupImg4_3} alt='' />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className='textWrap'>
            <h3 className='fadeInLeft'>가족, 회원의 건강 지킴이</h3>
            <h2>
              친구 초대 기능으로 연결된 가족, 회원의<br className='pcView' />
              신체 건강 한계치를 모니터링 할 수 있어<br className='pcView' />
              가족과 회원의 건강을 지킬 수 있습니다.
            </h2>
          </div>
        </div>
        <div className='step5'>
          <h3 className='fadeInUp'>AI 건강 상담</h3>
          <h2>
            스마트폰에 수집된 건강 정보를 활용하여<br className='pcView' />
            AI 기반 건강 상담 서비스를 제공합니다.
          </h2>
          <div>
            <img className='fadeInUp' src={AiChat1} alt='AI를 활용한 채팅 UI 이미지' />
          </div>
          <div>
            <img className='fadeInUp' src={AiChat2} alt='AI를 활용한 채팅 UI 이미지' />
          </div>
          <img className='BgCir' src={BgCir} alt='배경 원 일러스트 이미지' />
        </div>
      </div>
    </section>
  )
}

export default Main;