import React from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
// Import Swiper React components E

// css S
import '../css/about.css'
import '../css/about_res.css'
// css E

// source S
import Structure1 from '../images/about/structure1.png'
import Structure2 from '../images/about/structure2.png'
import Illust1 from '../images/about/illust_1.png'
import Illust2 from '../images/about/illust_2.png'
import ArrowBottom from '../images/about/arrow_bottom.svg'
// source E

function About() {
  return (
    <section id="About">
      <h2>
        <span>
          건강 모니터링 플랫폼<br />
          헬스가드
        </span>
      </h2>
      <h3 className='fadeInUp'>
        부상에 대한 사전 방지와 구급 환경을 제공하여<br></br>
        국민 건강을 지키는 헬스케어 브랜드 구축
      </h3>
      <div className='content1 wrap'>
        <h3 className='fadeInUp'>미션</h3>
        <ul>
          <li className='fadeInUp'>
            <h4>체육활동 증가 추세</h4>
            <p>
              생활체육 참여가<br />
              크게 증가하면서<br />
              체육활동 중 부상/사망 등 증가
            </p>
          </li>
          <li className='fadeInUp' style={{ transitionDelay: '0.15s' }}>
            <h4>위급 시 구급 환경 필요</h4>
            <p>
              신체 이상 시 사전 알림 및<br />
              위기 감지 시 119 등<br />
              비상 알림을 통해 구급 대응
            </p>
          </li>
          <li className='fadeInUp' style={{ transitionDelay: '0.3s' }}>
            <h4>의료비 절감 효과</h4>
            <p>
              체육활동 중 발생되는 부상과<br />
              위기에 대한 대처로<br />
              의료비 절감 및 손실 방지
            </p>
          </li>
        </ul>
      </div>
      <div className='content2 wrap'>
        <h3 className='fadeInUp'>서비스 구조</h3>
        <ul>
          <li className='content2Item1'>
            <img className='fadeInRight' src={Structure1} alt='워치 목업 이미지' />
            <div>
              <h4 className='fadeInLeft'>서비스 유저</h4>
              <p className='fadeInLeft'>
                건강 데이터 실시간 모니터링<br />
                건강 이상 감지 시 AI 상담<br />
                운동 방법 및 건강관리 정보
              </p>
            </div>
            <img className='illust' src={Illust1} alt='일러스트 이미지' />
          </li>
          <li className='content2Item2'>
            <img className='phoneHandImg fadeInRight' src={Structure2} alt='앱 목업 이미지' />
            <div>
              <h4 className='fadeInLeft'>Health Guard 플랫폼</h4>
              <p className='fadeInLeft'>
                가족/병원 등 비상 알람 등록<br />
                신체 이상 감지 시 자동 알림<br />
                AI 자동 대처 방안 안내
              </p>
              <h4 className='fadeInLeft'>위급상황 알림</h4>
              <p className='fadeInLeft'>
                신체 이상 감지 시 가족/병원 등 자동 알림을 통한 <br className='pcView' />
                구급으로 위급 상황 대처
              </p>
            </div>
            <img className='illust' src={Illust2} alt='휴대폰을 들고있는 사람 일러스트' />
          </li>
        </ul>
      </div>
      <div className='content3 wrap'>
        <h3 className='fadeInUp'>AI 배경기술</h3>
        <div>
          <div className='fadeInUp'>
            <div>
              <p>Wearable device<br /> Active Bio Sensor</p>
              <img src={ArrowBottom} alt='아래 화살표' />
              <p>Wearable device App</p>
              <img src={ArrowBottom} alt='아래 화살표' />
            </div>
            <div>
              <p>디지털 기기 프로세서	&#40;CPU&#41;</p>
              <img src={ArrowBottom} alt='아래 화살표' />
              <p>디지털 기기 메모리</p>
              <img src={ArrowBottom} alt='아래 화살표' />
              <p>디지털 기기 App</p>
            </div>
          </div>
          <div className='pcView fadeInUp'>
            <h4>AI 알고리즘 학습으로 개인 신체 한계치 도출</h4>
            <p>
              신체 한계치를 계산하는 인공지능 알고리즘 모델링<br />
              데이터 수집 및 전 처리 - 모델 설계 - 모델 학습 - 모델 평가 - 모델 적용<br />
              딥러닝 신경망의 활성화 함수와 손실 함수 수식 사용
            </p>
          </div>
          <div className='mobileView tabletView mobileSwiper'>
            <h4>AI 알고리즘 학습으로 개인 신체 한계치 도출</h4>
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
            >
              <SwiperSlide>
                <p className='swiperP'>신체 한계치를 계산하는 인공지능 알고리즘 모델링</p>
              </SwiperSlide>
              <SwiperSlide>
                <p className='swiperP'>데이터 수집 및 전 처리 - 모델 설계 - 모델 학습 - 모델 평가 - 모델 적용</p>
              </SwiperSlide>
              <SwiperSlide>
                <p className='swiperP'>딥러닝 신경망의 활성화 함수와 손실 함수 수식 사용</p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About;