import React, { useState } from "react";
import { useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";

// pages S
import Footer from "./footer";
import Main from "./pages/main";
import About from "./pages/about";
import CustomerSegment from "./pages/customer-segment";
import ValueProposition from "./pages/value-proposition";
// pages E

// css S
import './css/normalize.css'
import './css/style.css'
import './css/style_res.css'
// css E

// source S
import Logo from './images/common/logo.svg'
// source E

function Common() {

  // if (document.location.protocol == 'http:') {
  //   document.location.href = document.location.href.replace('http:', 'https:');
  // }

  const [gnbCount, setGnbCount] = useState(0)

  const handleClick = () => {
    if (gnbCount === 0) {
      document.getElementById('gnb').classList.add('on')
      setGnbCount(1)
    }
    if (gnbCount === 1) {
      document.getElementById('gnb').classList.remove('on')
      setGnbCount(0)
    }
  };

  const subGnbClick = () => {
    document.getElementById('gnb').classList.remove('on')
    setGnbCount(0)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const fadeInUpElements = document.getElementsByClassName('fadeInUp');
      const fadeInRightElements = document.getElementsByClassName('fadeInRight');
      const fadeInLeftElements = document.getElementsByClassName('fadeInLeft');
      const viewportHeight = window.innerHeight; // viewport 높이
      const scrollPosition = window.scrollY; // scroll top 값

      for (let i = 0; i < fadeInUpElements.length; i++) {
        const element = fadeInUpElements[i];
        const element_position = element.getBoundingClientRect().top + scrollPosition;

        if (element_position < scrollPosition + viewportHeight / 2 + 150) {
          element.classList.add('on');
        }
      }
      for (let i = 0; i < fadeInRightElements.length; i++) {
        const element = fadeInRightElements[i];
        const element_position = element.getBoundingClientRect().top + scrollPosition;

        if (element_position < scrollPosition + viewportHeight / 2 + 150) {
          element.classList.add('on');
        }
      }
      for (let i = 0; i < fadeInLeftElements.length; i++) {
        const element = fadeInLeftElements[i];
        const element_position = element.getBoundingClientRect().top + scrollPosition;

        if (element_position < scrollPosition + viewportHeight / 2 + 150) {
          element.classList.add('on');
        }
      }
    });
  })

  return (
    <div>
      <nav id="gnb">
        <ul>
          <li>
            <Link to="/">
              <img className="logo" src={Logo} onClick={subGnbClick} alt="로고 이미지" />
            </Link>
          </li>
          <li className="subLi">
            <ul>
              <li>
                <Link to="/about" onClick={subGnbClick}>서비스 소개</Link>
              </li>
              <li>
                <Link to="/customer-segment" onClick={subGnbClick}>고객 Segment</Link>
              </li>
              <li>
                <Link to="/value-proposition" onClick={subGnbClick}>가치 제안</Link>
              </li>
            </ul>
          </li>
          <li className="mobileBtn mobileView">
            <button onClick={handleClick}>
              <div>line1</div>
              <div>line2</div>
              <div>line3</div>
            </button>
          </li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/customer-segment" element={<CustomerSegment />} />
        <Route path="/value-proposition" element={<ValueProposition />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default Common;