// css S
import '../css/customer-segment.css'
import '../css/customer-segment_res.css'
// css E

// source S
import UiImg1 from '../images/customer_segment/ui_img1.png'
import UiImg2 from '../images/customer_segment/ui_img2.png'
import UiImg3 from '../images/customer_segment/ui_img3.png'
// source E

function CustomerSegment() {
  return (
    <section id="CustomerSegment">
      <h2><span>고객 Segment</span></h2>
      <ul className='wrap'>
        <li>
          <img className='fadeInUp' src={UiImg1} alt='헬스케어 앱 UI 이미지' />
          <div>
            <h4 className='fadeInLeft'>가족을 위한 헬스가드</h4>
            <h3>
              노약자 및 유아 등 가족 건강<br className='pcView' />
              모니터링 및 신체 한계치<br className='pcView' />
              도달 직전 부상 방지
            </h3>
            <p>
              심박수의 모니터링으로 인해 심정지 등의<br className='pcView' />
              직전 이상 징후 발견 가능
              </p>
          </div>
        </li>
        <li>
          <div>
            <h4 className='fadeInLeft'>회원을 위한 헬스가드</h4>
            <h3>
              헬스장 등 회원제로 운영되는<br className='pcView' />
              시설에서 회원의 건강 모니터링 및<br className='pcView' />
              신체 한계치 도달 직전 부상 방지
            </h3>
            <p>
              운동 중 건강 모니터링을 통한 부상 예방 및<br className='pcView' />
              적극적인 운동 참여 유도
              </p>
          </div>
          <img className='fadeInUp' src={UiImg2} alt='헬스케어 앱 UI 이미지' />
        </li>
        <li>
          <img className='fadeInUp' src={UiImg3} alt='헬스케어 앱 UI 이미지' />
          <div>
            <h4 className='fadeInLeft'>공공기관 독거노인을 위한 헬스가드</h4>
            <h3>
              공공기관에서 독거 노인 외출 시<br className='pcView' />
              건강 상태 모니터링 및 경고<br className='pcView' />
              알람 서비스
            </h3>
            <p>혼자 사는 어르신 분들이 안심하고 외출 가능</p>
          </div>
        </li>
      </ul>
    </section>
  )
}

export default CustomerSegment;